/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {Information} from './Show/information';
import Navbar from '../../components/Navbar';
import {useParams} from 'react-router-dom';

export const UserShowIndex = () => {
  
  const {id} = useParams();

  const routes= [
    {name:'Informacion', 'route':`/usuarios/${id}`},
  ]
  return (
    <>
    <Navbar routes={routes} />

    </>
  )
}


export const UserShowRoutes = [
  { path:"", Element:Information },
]