import React from "react"
import {Link} from 'react-router-dom'

const Show=({route, icon="fas fa-eye"})=> {
    

    return (
      <>
      <Link to={route}> <i style={{color:'#fc6452d9'}} className={icon}></i></Link>
      </>
    )
  }

export default Show;