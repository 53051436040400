import {authAxios, authAxiosMedia} from './../../config/axios';

class Member{
	get=(club_id, params)=>{
		return authAxios.get(`/community/clubs/${club_id}/members`, {
			params
		});
	}	
	
	save=(club_id, data)=>{
		return authAxios.post(`/community/clubs/${club_id}/members`,  data);
	}	
	

	update=(club_id, data)=>{
		return authAxios.put(`/community/clubs/${club_id}/members`, data);
	}	

	delete=(id)=>{
		return authAxios.delete(`/community/members/${id}`);
	}	
}

export default (new Member());
