/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import Navbar from '../../components/Navbar';
import {Summaries} from './Summaries';
export const SummariesIndex = () => {
  
  const routes= [
    {name:'Resumenes', 'route':'/resumenes'},

  ]
  
  return (
    <>
    <Navbar routes={routes} />

    </>
  )
}

export const SummariesRoutes= [
  {path:'',  Element:Summaries},

]