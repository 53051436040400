import {authAxios} from './../../config/axios';

class Profile{	
	updateMine=(data)=>{
		return authAxios.put(`/administration/profile/update`,data);
	}	

	update=(id,data)=>{
		return authAxios.put(`/administration/profile/${id}/update`,data);
	}	

	changePhoto=(data)=>{
		let formData = new FormData();
		formData.append('photo',data)
		return authAxios.post(`/administration/profile/photo`,formData);
	}	
 
}

export default (new Profile());
