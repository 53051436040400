import {authAxios, authAxiosMedia} from './../../config/axios';

class Club{
	get=(params)=>{
		return authAxios.get('/community/clubs', {
			params
		});
	}	
	show=(id)=>{
		return authAxios.get(`/community/clubs/${id}`);
	}	

	save=(data)=>{
		return authAxiosMedia.post('/community/clubs', data);
	}
	updatePhoto=(id,file)=>{
		let formdata = new FormData();
		formdata.append('image', file);

		return authAxiosMedia.post(`/community/clubs/${id}/image`, formdata);
	}
	update=(id, data)=>{
		return authAxios.put(`/community/clubs/${id}`, data);
	}


	delete=(id)=>{
		return authAxios.delete(`/community/clubs/${id}`);
	}		
}

export default (new Club());
