/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Votes from './votes'
import Challenges from './Challenges'
import ChallengesShow from './Challenges/Show'
import Events from './Events';
import EventsShow from './Events/show';
import Navbar from '../../components/Navbar';
import Comments from './Events/Comments'

export const CommunityIndex = () => {
  

  const routes= [
    {name:'Votos', 'route':'/comunidad'},
    {name:'Retos', 'route':'/comunidad/retos'},
    {name:'Noticias', 'route':'/comunidad/noticias'},
    {name:'Eventos', 'route':'/comunidad/eventos'},
  ]
  
  return (
    <>
    <Navbar routes={routes} />

    </>
  )
}

export const CommunityRoutes= [
  {path:'',  Element:Votes},
  {path:'retos',  Element:Challenges},
  {path:'retos/:id',  Element:ChallengesShow},
  {path:`noticias`, Element:Events,  data:{
    is_news:1
  }},
  {path:`noticias/:id`, Element:EventsShow,  data:{
    is_news:1
  }},,
  {path:`eventos`, Element:Events,  data:{
    is_news:0
  }},,
  {path:`eventos/:id`, Element:EventsShow,  data:{
    is_news:0
  }},

  {path:`eventos/:id/comentarios`, Element:Comments},
]