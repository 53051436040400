import {authAxios} from './../../config/axios';

class Documents{	
	save=(data)=>{
		let formData = new FormData();
		for(let k in data)
			formData.append(k,data[k]);

		return authAxios.post(`/administration/documents/save`,formData);
	}	


	get=(params=[])=>{
		return authAxios.get(`/administration/documents`,{
			params
		});
	}

	delete=(id)=>{
		return authAxios.delete(`/administration/documents/${id}`);
	}	
}

export default (new Documents());
