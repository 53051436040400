import {
  Stack
} 
from "@chakra-ui/react";
import React from "react";
import { getClubsAsync } from "../../redux/club/actions";
import DataTables from '../../components/Datatable';
import { Create } from './Create';
import { Edit, Show} from '../../components/buttons';

export const Clubs = () => {

  const selectable = (state)=>(state.club.clubs);

  const columns = (row)=>{
    return {
      _: <img width="30" src={row?.image} />,
      nombre : row.name,
      miembros: row.members_count,
      description: row.description,
      ciudad : row.city,
      admin: <a href={`/usuarios/${row.created_by}`}> {row?.admin.username} </a>,
      opciones:(
        <div>
            <div>
              <Edit route={`/clubs/${row.id}/editar`} />
              <Show route={`/clubs/${row.id}`} />         
              <Show route={`/clubs/${row.id}/miembros`} icon="fas fa-users"/>         
             </div>
        </div>),
    }
  } 

  const dispatchable=(query)=>{
    return getClubsAsync({
      ...query,
      with:'admin:id,username'
    });
  } 



  return (
    <Stack  style={{ display: "flex", flexDirection: "column" }} bg={"white"}>
        
        <Create />
        <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          selectable={selectable}
          title='Gestion de clubs'
        />
    
    </Stack>
  );
};
