import {
  Stack
} from "@chakra-ui/react";
import React from "react";
import { getCommentsAsync } from "../../../redux/community/actions";
import DataTables from '../../../components/Datatable';
import { useParams} from 'react-router-dom';


export default function Comments() {



    const { id } = useParams()

  const selectable = (state)=>(state.community.comments);

  const columns = (row)=>{



    return {
      comentario: row?.comment,
      usuario: <a href={`/usuarios/${row?.user_id}`} >{row?.user?.username}</a>

    }
  } 

  const dispatchable=(query)=>{

      return getCommentsAsync({
        ...query,
        event_id:id
      });

  } 




  return (
    <Stack style={{ display: "flex", flexDirection: "column" }} bg={"white"}>
        
 
        <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          selectable={selectable}
          title='Comentarios'
        />
      
    </Stack>
  );
}
