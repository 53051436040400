import React, {useState} from 'react';
import {Input, SimpleGrid, Button } from '@chakra-ui/react'
import NewUsers from './NewUsers';
import moment from 'moment';
import {toast} from 'react-toastify';



export const Summaries = () => {

    const [start, setStart] = useState(moment().startOf('week').toISOString().split('T')[0]);
    const [end, setEnd] = useState(moment().toISOString().split('T')[0]);
    
    const [searchStart, setSearchStart] = useState(moment().startOf('week').toISOString().split('T')[0]);
    const [searchEnd, setSearchEnd] = useState(moment().toISOString().split('T')[0]);

    const handleStart= (e)=>{
        setSearchStart(e.target.value.toString())

    }

    const handleEnd= (e)=>{
        setSearchEnd(e.target.value.toString())
    }

    const onSearch = ()=>{
        setStart(searchStart);
        setEnd(searchEnd);
        toast.info("...Buscando",{"theme":"dark",autoClose:1000})

    }


    return <div >
    <SimpleGrid columns={3} spacing={10}>
        <Input
         placeholder="Select Date and Time"
         size="md"
         type="date"
        onChange={handleStart}
        defaultValue={start}

        />
                <Input
         placeholder="Select Date and Time"
         size="md"
         defaultValue={end}
         type="date"
         onChange={handleEnd}
        />

        <Button onClick={onSearch}>Buscar</Button>
        </SimpleGrid>

    <SimpleGrid marginTop="16px" columns={3} spacing={10} >
        <NewUsers start={start} end={end}/>
    </SimpleGrid>    



    </div>
}
 