/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Events from '../Community/Events';
import Navbar from '../../components/Navbar';
import { useParams} from 'react-router-dom';

export const ClubsShowIndex = () => {
  
    const { id } = useParams()


  const routes= [
    {name:'Noticias', 'route':`/clubs/${id}`},
  ]
  
  return (
    <>
    <Navbar routes={routes} />

    </>
  )
}

export const ClubsShowRoutes= [
  {path:``, Element:Events,  data:{
      is_news:1
  }}


]