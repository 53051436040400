import {authAxios} from './../../config/axios';

class User{
	get=(params=[])=>{
		return authAxios.get('/administration/users',{
			params
		});
	}

	show=(id)=>{
		return authAxios.get('/administration/users/'+id);
	}	

	save=(data)=>{
		return authAxios.post('/administration/users',data);
	}

	update=(id,data)=>{
		return authAxios.put(`/administration/users/${id}`,data);
	}	

	ban=(id)=>{
		return authAxios.post(`/administration/users/${id}/ban`);
	}	

	unban=(id)=>{
		return authAxios.post(`/administration/users/${id}/unban`);
	}

	unlock=(id)=>{
		return authAxios.post(`/administration/users/${id}/unlock`);
	}		
	
	delete=(id)=>{
		return authAxios.post(`/administration/users-delete/delete/${id}`);
	}	
	
	updateMine=(data)=>{
		return authAxios.put(`/administration/users/update`,data);
	}


	//trash
	trash=(params=[])=>{
		return authAxios.get('/administration/users/trash',{
			params
		});
	}

		//trash
	unDelete=(id)=>{
		return authAxios.post(`/administration/users/${id}/unDelete`);
	}

}

export default (new User());
