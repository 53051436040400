import {  createReducer } from "@reduxjs/toolkit";
import types from './actionTypes';

const initialState = {
    clubs:[],
    members:[]
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(types.setClubs, (state, action) => {
      state.clubs = action.payload;
    })
    .addCase(types.setMembers, (state, action) => {
      state.members = action.payload;
    })
});