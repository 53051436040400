/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from '@chakra-ui/react'
import React, {useState } from 'react'
import { Modal, OpenModal} from '../../../components/Modal';
import Builder from '../../../components/forms';
import { saveMemberAsync } from '../../../redux/club/actions';
import { useDispatch } from 'react-redux';
import SelectUser from '../../../components/select/user';
import { useParams} from 'react-router-dom';


export const Create = () => {

 
  const { id } = useParams()

    const [user_id,setUser] = useState(false)
    const disclousure = useDisclosure()
    const dispatch = useDispatch();
    const [fields]= useState([
      {
        name:'role',
        label:'Rol',
        options: <><option>administrador</option><option>usuario</option></>,
        required:true
      },
     
    ]);



  const saveMember = (data)=>{


    if(!user_id) {
      return
    }

    data.user_id = user_id
    dispatch(saveMemberAsync(id, data));
  }


    return (
     <>
       
       <OpenModal disclousure={disclousure}/>

       <Modal
         isOpen={disclousure.isOpen}
         onClose={disclousure.onClose}

        >

        <SelectUser  onChange={setUser}/>
        <Builder fields={fields}   message='Agregar miembro' onClick={saveMember} />


         
       </Modal>
        
     </>
    )
  
}