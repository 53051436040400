import {  useDisclosure } from '@chakra-ui/react'
import { useDispatch} from "react-redux";
import { saveEventAsync } from '../../../redux/community/actions'
import Builder from '../../../components/forms';
import {Modal, OpenModal}  from '../../../components/Modal';

export const Create = ({is_news, club_id=false}) => {
    const dispatch = useDispatch()
    
    const title = is_news ? 'noticia' : 'evento';

    const disclousure = useDisclosure();

    const extra_fields = is_news ? [] : [
        {
            name:'price',
            label:'Precio de entrada',
            defaultValue:'',
            required:true
        },  
        {
            name:'currency',
            label:'Moneda',
            defaultValue:'',
            required:true
        },  
        {
            name:'clabe',
            label:'CLABE',
            defaultValue:'',
            required:true
        },
        {
            name:'concept',
            label:'Concepto',
            defaultValue:'',
            required:true
        },
        {
            name:'location',
            label:'Ubicacion',
            defaultValue:'',
            required:true
        },
        {
            name:'date',
            label:'Fecha',
            defaultValue:'',
            required:true
        },    
    ]

    const fields =[
        {
            name:'title',
            label:'titulo',
            defaultValue:'',
            required:true
        },        
        {
            name:'body',
            label:'Contenido',
            required:true,
            textarea:true
        }, 
        {
            name:'image',
            label:'Imagen',
            required:true,
            file:true,
        },
        ...extra_fields
   ];

   const saveVote=(data)=>{

        if(club_id) {
            data.club_id=club_id
        }

        dispatch(saveEventAsync(data))
        disclousure.onClose();
    }



  return (
    <>
          <OpenModal text={`Agregar ${title}`} disclousure={disclousure} />
          <Modal
            isOpen={disclousure.isOpen}
            onClose={disclousure.onClose}
          >
            <Builder fields={fields}  message={`Guardar ${title}`} onClick={saveVote}  />
          </Modal>

    </>
  )
}
