import { SimpleGrid, Box, Heading, Image} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { useParams} from 'react-router-dom';
import { clubApi } from '../../api';
import {toast} from 'react-toastify';
import Builder from '../../components/forms';
import InputFile from '../../components/forms/InputFile';
import SelectUser from '../../components/select/user';

export const ClubsEdit = () => {

    const { id } = useParams()
    const [club, setClub]  = useState({});
    const [fieldsClub, setFieldsClub] = useState([]);
    const saveImage= (e)=>{

        clubApi.updatePhoto(club.id, e.target.files[0] ).then(res=>setClub(res.data.data))
    }

    useEffect(()=>{
        setFieldsClub([
        {
            name:'name',
            label:'Nombre',
            required:true,
            defaultValue:club.name,
        },        
        {
            name:'description',
            label:'Descripcion',
            required:true,
            defaultValue:club.description,
        },        
        {
            name:'city',
            label:'Descripcion',
            required:true,
            defaultValue:club.city,
        }
        ])

    },[club]);


    useEffect(()=>{
        if(id) {           
            clubApi.show(id).then(response=>{
                setClub(response.data.data);
            });
        }

    }, [id]);

    const saveClub = (data)=>{

        clubApi.update(club.id,data).then(response=>{
            console.log(response.data.data);
            setClub(response.data.data);
            toast.success("Club actualizado correctamente", {"theme":"dark"});
        })

    }


    return (
        <SimpleGrid columns={2} spacingX='40px' spacingY='20px'>

            <Box p={5} shadow='md' borderWidth='1px' >
                <Heading fontSize='xl'>Editar usuario</Heading>

                <Image 
                    boxSize='200px' 
                    style={{"margin":"1rem auto"}}
                    src={club?.image  ?  club.image : 'https://cdn-icons-png.flaticon.com/512/149/149071.png' }>
                </Image>


               {club?.id && <InputFile  accept={['image/png', 'image/gif', 'image/jpeg', 'image/webp']} onChange={saveImage} />}
                <Builder fields={fieldsClub} onClick={saveClub}/>


            </Box>



            
        </SimpleGrid>


        )

}
