import {clubApi, memberApi} from '../../api';
import types from './actionTypes';
import {toast} from 'react-toastify';

export const getClubsAsync = (params=[]) => async (dispatch) => {
    const response = await clubApi.get(params);
    dispatch(types.setClubs(response.data.data));

}

export const saveClubAsync = (data) => async (dispatch, state) => {
    const response = await clubApi.save(data);
    toast.success('Club creado correctamente', {"theme":"dark"})
    let clubs = JSON.parse(JSON.stringify(state().club.clubs));

    clubs.data.push(response.data.data);
    dispatch(types.setClubs(clubs));

}

// export const deleteRolesAsync = (id) => async (dispatch, state) => {
//     const response = await clubApi.delete(id);
//     toast.success('Role eliminado correctamente', {"theme":"dark"})
//     let clubs = JSON.parse(JSON.stringify(state().club.clubs));
//     clubs.data = clubs.data.filter(club=>club.id !== id);
//     dispatch(types.setRoles(clubs));
//     return response;
// }




export const getMembersAsync = (club_id, params=[]) => async (dispatch) => {
    const response = await memberApi.get(club_id, params);
    dispatch(types.setMembers(response.data.data));

}



export const deleteMemberAsync = (id) => async (dispatch, state) => {
    const response = await memberApi.delete(id);
    toast.success('Miembro eliminado correctamente', {"theme":"dark"})
    let clubs = JSON.parse(JSON.stringify(state().club.members));
    clubs.data = clubs.data.filter(club=>club.id !== id);
    dispatch(types.setMembers(clubs));
    return response;
}



export const saveMemberAsync = (id, data) => async (dispatch, state) => {
    const response = await memberApi.save(id, data);
    toast.success('Club creado correctamente', {"theme":"dark"})
    let members = JSON.parse(JSON.stringify(state().club.members));


    members.data.push(response.data.data);
    dispatch(types.setMembers(members));
}
