
import { documentApi } from '../../api';
import types from './actionTypes';
import {toast} from 'react-toastify';




///documents

export const getDocumentsAsync = (id,params) => async (dispatch) => {
    const response = await documentApi.get(id,params);
    dispatch(types.getDocuments(response.data.data))
}

export const saveDocumentAsync = (data) => async (dispatch,state)=>{
    const response = await documentApi.save(data);
    toast.success('Usuario guardado correctamente', {"theme":"dark"})
    let docs = JSON.parse(JSON.stringify(state().document.documents));

    docs.data.push(response.data.data);
    dispatch(types.getDocuments(docs));

}
export const deleteDocumentAsync = (row_id) => async(dispatch, getState) => {
    await documentApi.delete(row_id);
    let currentState = JSON.parse(JSON.stringify(getState().document.documents));

    currentState.data = currentState.data.filter(row=>row.id!==row_id);

    toast.success('Documento eliminado correctamente', {'theme':"dark"});
    dispatch(types.getDocuments(currentState))
}