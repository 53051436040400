import {combineReducers} from 'redux'

import auth from './auth/reducer'
import menu from './menu/reducer'
import role from './role/reducer'
import user from './user/reducer'
import document from './document/reducer'
import club from './club/reducer'
import community from './community/reducer'

const reducers = combineReducers({
    auth,
    menu,
    role,
    user,
    document,
    community,
    club
});

export default reducers;
