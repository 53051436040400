import { Dashboard } from '../pages/Dashboard';


import { UserIndex, UserRoutes } from '../pages/Users/index';
import { UserShowIndex, UserShowRoutes } from '../pages/Users/Show';
import { Edit } from '../pages/Users/Edit';
import { Profile } from '../pages/Users/Profile';
import { Documents } from '../pages/Document';
import { Tickets } from '../pages/Ticket'; 
import { Roles } from '../pages/Roles'; 
import { SummariesIndex, SummariesRoutes } from '../pages/Summaries'; 
import { CommunityIndex, CommunityRoutes } from '../pages/Community'; 
import { Clubs } from '../pages/Clubs'; 
import { ClubsEdit } from '../pages/Clubs/Edit'; 
import { Members } from '../pages/Clubs/Members'; 
import { ClubsShowIndex, ClubsShowRoutes } from '../pages/Clubs/Show';  


export const admin=[
     {path:'/inicio', roles:[], Component:Dashboard},

     {path:'/usuarios',  roles:[], Component:UserIndex, Children:UserRoutes},
     {path:'/usuarios/:id',  roles:[], Component:UserShowIndex, Children:UserShowRoutes},
     {path:'/usuarios/:id/editar',  roles:[], Component:Edit},
     {path:'/usuarios/perfil',   roles:[], Component:Profile},

     {path:'/roles',  roles:[], Component:Roles},

     {path:'/documentos',  roles:[], Component:Documents},

      {path:'/tickets',  roles:['administracion'], Component:Tickets},

     {path:'/resumenes',  roles:[], Component:SummariesIndex, Children:SummariesRoutes},

     {path:'/comunidad',  roles:[], Component:CommunityIndex, Children:CommunityRoutes},
     
     {path:'/clubs',  roles:[], Component:Clubs },
     {path:'/clubs/:id/editar',  roles:[], Component:ClubsEdit },
     {path:'/clubs/:id/miembros',  roles:[], Component:Members },
     {path:'/clubs/:id',  roles:[], Component:ClubsShowIndex, Children:ClubsShowRoutes },



]
