import {
  Stack
} from "@chakra-ui/react";
import React from "react";
import { getEventsAsync, deleteEventAsync } from "../../../redux/community/actions";
import DataTables from '../../../components/Datatable';
import {Create} from './create';
import {Delete, Show} from '../../../components/buttons';
import { useParams} from 'react-router-dom';


export default function Events({data:{is_news}}) {



    const { id } = useParams()

  const selectable = (state)=>(state.community.events);


  const deleteDispatch =(row_id)=>{
    return  deleteEventAsync(row_id);
  }

  const columns = (row)=>{

     const extra_fields = is_news ? {} : {
      precio:`${row.currency} ${row.price}`,

      clabe:row.clabe,
      concepto:row.concept,
      ubicacion:row.location,
      fecha:row.date
     }

    return {
      img: <img alt='' src={row.image} width="50px"/>,
      title:  row.title,
      ...extra_fields,
      likes: row?.likes_count,
      comentarios: row?.comments_count,
      opciones:(
        <div>
          <Show icon="fas fa-comments" route={`/comunidad/eventos/${row.id}/comentarios`} /> 
          <Show route={`/comunidad/noticias/${row.id}`} /> 
          <Delete id={row.id} dispatchable={deleteDispatch}/>
        </div>
      )

    }
  } 

  const dispatchable=(query)=>{


    if(id) {
      return getEventsAsync({
        ...query,
        is_news,
        club_id:id
      });
    } 

    return getEventsAsync({
      ...query,
      is_news,
      club_id:'_NULL_'
    });
  } 




  return (
    <Stack style={{ display: "flex", flexDirection: "column" }} bg={"white"}>
        
        <Create club_id={id} is_news={is_news} />
 
        <DataTables  
          columns={columns}
          fire={is_news+1}
          dispatchable={dispatchable}
          selectable={selectable}
          title=''
        />
      
    </Stack>
  );
}
