import {authAxios} from './../../config/axios';

class Summary{
	newUsers=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/administration/reports/register`,formData);
	}

}


export default (new Summary());
