/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from '@chakra-ui/react'
import React, {useState } from 'react'
import { Modal, OpenModal} from '../../components/Modal';
import Builder from '../../components/forms';
import { saveClubAsync } from '../../redux/club/actions';
import { useDispatch } from 'react-redux';
import SelectUser from '../../components/select/user';

export const Create = () => {

 

    const [user_id,setUser] = useState(false)
    const disclousure = useDisclosure()
    const dispatch = useDispatch();
    const [fields]= useState([
      {
        name:'name',
        label:'Nombre',
        defaultValue:'',
        required:true
      },
      {
        name:'description',
        label:'Descripcion',
        defaultValue:'',
        required:true
      },
      {
        name:'city',
        label:'Ciudad',
        defaultValue:'',
        required:true
      },
      {
        name:'image',
        label:'Imagen de portada',
        file:true,
        required:true
      },
    ]);



  const saveRole = (data)=>{


    if(!user_id) {
      return
    }

    data.user_id = user_id
    dispatch(saveClubAsync(data));
  }


    return (
     <>
       
       <OpenModal disclousure={disclousure}/>

       <Modal
         isOpen={disclousure.isOpen}
         onClose={disclousure.onClose}

        >

        <SelectUser  onChange={setUser}/>
        <Builder fields={fields}   message='Crear club' onClick={saveRole} />


         
       </Modal>
        
     </>
    )
  
}