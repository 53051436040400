import {
  Stack
} 
from "@chakra-ui/react";
import React from "react";
import { getMembersAsync, deleteMemberAsync } from "../../../redux/club/actions";
import DataTables from '../../../components/Datatable';
import { Delete, Edit, Show} from '../../../components/buttons';
import { useParams} from 'react-router-dom';
import { Select } from "@chakra-ui/react"
import {memberApi} from '../../../api'
import {Create} from './Create';



export const Members = () => {

  const selectable = (state)=>(state.club.members);
  const { id } = useParams()
  
  const deleteDispatch =(row_id)=>{
    return  deleteMemberAsync(row_id);
  }



  const updateRole = (role, row) =>{
    memberApi.update(id, {
      ...row,
      role
    });

  }
  
  const columns = (row)=>{
    return {
      nombre : row.user?.username,
      rol: 

      <Select defaultValue={row?.role} onChange={(e)=> updateRole(e.target.value, row)}>
        <option>administrador</option>
        <option>usuario</option>
      </Select>,

      opciones:(
        <div>
            <div>
              <Delete id={row.id} dispatchable={deleteDispatch}/>       
             </div>
        </div>),
    }
  } 

  const dispatchable=(query)=>{
    return getMembersAsync(id, {
      ...query,
      with:'user:id,username'
    });
  } 



  return (
    <Stack  style={{ display: "flex", flexDirection: "column" }} bg={"white"}>
        
        <Create />
        <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          selectable={selectable}
          title='Gestion de miembros'
        />
    
    </Stack>
  );
};
