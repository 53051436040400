import {  Stack , useDisclosure } from '@chakra-ui/react'
import React, {useState, useEffect } from 'react'
import { getDocumentsAsync,saveDocumentAsync,deleteDocumentAsync } from '../../redux/document/actions';
import DataTables from '../../components/Datatable';
import {Open,Delete} from '../../components/buttons';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, OpenModal}  from '../../components/Modal';
import Builder from '../../components/forms';


  export const Documents = () => {

    const disclousure = useDisclosure();




    const [fields, setFields] = useState([]);


    useEffect(()=>{
     

        setFields([
        {
          name:'name',
          required:true,
          defaultValue:'',
          label:'Nombre'
        },
        {
          name:'file',
          required:true,
          label:'Archivo',
          file:true,
        },

        ]);
  
    },[]);


    const dispatch = useDispatch();
    


  const dispatchable=(query)=>{
    return getDocumentsAsync({
      ...query,
 //    select:'id,stars,comment,title'
    });
  } 


  const deleteDispatch =(row_id)=>{
    return  deleteDocumentAsync(row_id);
  }

   const selectable = (state)=>(state.document.documents);

      const columns = (row)=>{
        return {
          name: row.name+row.extension,
          opciones:<div>
            <Open route={row.file} />
             <Delete id={row.id} dispatchable={deleteDispatch} />
          </div>
     
        }
      } 




    const saveDocument =(data)=>{
      dispatch(saveDocumentAsync(data));
    }
    return (
        <Stack  style={{ display: "flex", flexDirection: "column" }} bg={"white"}>  

          <OpenModal text='Agregar documento' disclousure={disclousure} />
          <Modal
            isOpen={disclousure.isOpen}
            onClose={disclousure.onClose}
          >
            <Builder fields={fields}  message='Guardar nueva version' onClick={saveDocument}  />
          </Modal>


            <DataTables  
              columns={columns}
              dispatchable={dispatchable}
              selectable={selectable}
              title='Gestion de versiones'
            />
        </Stack>

       

    )
}
 